<template>
  <el-header>
    <div v-if="isGMHerom2()" class="logo" @click="toFirstPage"><img alt="图标" src="@/assets/common/Blogo2.png"/><span
        class="pjname" >GM服务平台</span></div>
    <div v-else class="logo" @click="toFirstPage" id="pjname"><img alt="图标" src="@/assets/common/Wlogo.png"/><span class="pjname">{{isSandBox() ? '沙盒推广平台' : 'GM服务平台'}}</span>
    </div>
    <div class="header-right">
      <ul>
        <li v-for="(route, index) in addRoutes"
            v-if="!route.children || (route.children.length<=1) && !route.permission" :key="route.path + index"
            @click="toPage(route)">
          <span> {{ route.meta.title }}</span>
        </li>
        <li v-for="(route, index) in addRoutes"
            v-if="(route.children && route.children.length > 1 && !route.permission)" :key="route.path + index">
          <el-dropdown>
            <span>{{ route.meta.title }}</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(route2,index) in route.children" :key="route2.path + index"
                                @click.native="toPage(route2,2,route)">{{ route2.meta.title }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>

        <li>
          <el-dropdown>
            <div class="info_box" >
              <div v-if="$store.state.user.userInfo.avatar!==''" class="avator"><img
                  :src="$store.state.user.userInfo.avatar" alt=""/></div>
              <span class="username">{{ $store.state.user.userInfo.username }}</span>
              <span
                  v-show="$store.state.user.roleId == 'GM'">{{
                  $store.state.user.userInfo.status == 1 ? '（已审核）' : '(待审核)'
                }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="$store.state.user.roleId === 'GM'" @click.native="toHomePage">账号信息
              </el-dropdown-item>
              <el-dropdown-item v-if="$store.state.user.roleId === '推广员'" @click.native="toHomePage2">个人信息
              </el-dropdown-item>
              <el-dropdown-item v-if="!isSandBox()" @click.native="toWallet">我的钱包</el-dropdown-item>
              <el-dropdown-item @click.native="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
  </el-header>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Head',
  computed: {
    ...mapGetters(['addRoutes'])
  },
  data() {
    return {
      username: '名字',
    }
  },
  methods: {
    toHomePage() {
      this.$emit("pathSelect",'/user/info')
      this.$router.push({path: '/user/info'})
    },
    toHomePage2() {
      this.$emit("pathSelect",'/user/promotion')
      this.$router.push({path: '/user/promotion'})
    },
    toWallet() {
      this.$emit("pathSelect",'/user/wallet')
      this.$router.push({path: '/user/wallet'})
    },
    toFirstPage() {
      this.$router.push({path: '/master/page'})
    },
    loginOut() {
      this.$store.dispatch('logout')
      this.$router.push({path: '/login'})
      // location.reload();
    },
    toPage(route, type = 1, Proute = null) {
      if (type == 2) {
        const menus = []
        Proute.children.forEach(item => {
          menus.push({name: item.name, path: item.path})
        });
        this.$emit("pathSelect", route.path)
        this.$router.push({name: route.name, params: {menus: JSON.stringify(menus)}})

      } else {
        if (route.children) {
          const menus = []
          route.children.forEach(item => {
            menus.push({name: item.name, path: item.path})
          })
          this.$router.push({name: route.children[0].name, params: {menus: JSON.stringify(menus)}})
        } else {
          this.$router.push({path: route.path})
        }

      }

    },
  }
}
</script>
<style lang="less" scoped>
.el-header {
  display: flex;
  background: #121644;
  justify-content: space-between;
}

.pjname {
  color: white;
  margin-left: 10px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
  }
}

.header-right {
  float: right;
  display: flex;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 20px 0 20px;
      box-sizing: border-box;
      font-size: 14px;

      span {
        font-weight: 450;
        cursor: pointer;
        color: #FFFFFF;

        &:hover {
          color: #3c81fe
        }
      }
    }
  }
}

.info_box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .avator {
    img {
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }

  .username {
    margin-left: 10px;
    cursor: pointer;
    overflow: hidden;
    width: 70px;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 60px;
  }
}

// /deep/.el-dropdown-menu__item {
//     box-sizing:border-box;
//     padding: 0 0;
//      a{
//         display: block;
//         width: 100%;
//         padding: 0 20px;
//         box-sizing: border-box;
//     }

// }
</style>