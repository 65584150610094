<template>
    <el-aside width="200px">
        <el-menu
            router
            background-color="#fff"
            text-color="#20232d"
            active-text-color="#3c81fe"
            class="el-menu-vertical-demo"
            :default-active="selected"
            :unique-opened='true'
        >
            <!-- 只有一级菜单 -->
            <template v-for="(item, index) in menus">
                <el-menu-item
                    v-if="!item.children"
                    :key="index"
                    :index="item.path"
                    :route="{path:item.path,query:{id:id}}"
                    :id="item.path"
                >
                    <span class="span4" slot="title">{{ item.name }}</span>
                </el-menu-item>
                <!-- 有二级菜单 -->
                <el-submenu v-else :key="index" :index="index + '' "  :id="item.path">
                    <template slot="title">
                        <span class="span4" slot="title">{{ item.name }}</span>
                    </template>
                    <template v-for="(etem, index) in item.children">
                        <el-menu-item
                            style="background: none; border-radius: 0"
                            :index="etem.path"
                            :route="{path:etem.path,query:{id:id}}"
                            :key="index"
                            >
                            {{ etem.name }}
                        </el-menu-item>
                    </template>
                </el-submenu>
            </template>
        </el-menu>
    </el-aside>
</template>
<script>
export default {
    name:'Aside',
    props:{
        id: {
            type: String
        },
        selected: {
            type: String
        },
        menus: {
            type: Array,
            default: function () {
                return []
            }
        },
    },
    data(){
        return{
            username:'名字',
        }
    },
    methods:{
        
    }
}
</script>
<style lang="less" scoped>
.el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
}
</style>