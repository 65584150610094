const getters = {
    token:state => state.user.token,
    userInfo: state => state.user.userInfo,
    routes: state => state.user.routes,
    addRoutes:state=>state.user.addRoutes,
    permission:state=>state.user.btnlist,
    roleName:state=>state.user.roleId,
    
    
}

export default getters