<template>
    <el-container direction="vertical">
        <Head @getMenus="getMenus" @pathSelect="changePath"></Head>
        <el-container>
            <Aside :menus="menus" :selected="selected" :id="id" v-if="menus.length > 0"></Aside>
            <el-main><router-view /></el-main>
        </el-container>
    </el-container>
</template>

<script>
import Head from '@/components/common/head.vue'
import Aside from '@/components/common/aside.vue'
export default {
    components:{
        Head,
        Aside
    },
    data(){
        return{
            username:'名字',
            id: '',
            selected: '',
            menus: [
            ],
        }
    },
    methods: {
        getMenus(menus){
           this.menus = JSON.parse(menus)
        },
        changePath(path){
            this.selected = path
        },
    },
    watch:{
        '$route'(to,from){
           
        }
    }
}
</script>

<style lang="less" scoped>
// .el-main{
//     padding: 0px;
// }
.el-main {
  padding: 15px;
  background: #ECF0F5;
  height:calc(100vh - 60px);
}
.el-container{
    min-width: 500px;
    height: 100%;
}
.normal{
    color: white;
}
.el-dropdown-link {
    cursor: pointer;
}
.application {
    color:#409EFF;
    &:hover {
        color: #409EFF;
    }
}
.el-dropdown-menu {
  top: 45px !important;
}
.el-menu-vertical-demo.el-menu{
  height: 100%;
  border-right:none
}
/deep/.el-menu-item.is-active {
  // 被点击以后字体颜色改变
  position: relative;
  background-color: rgba(60,129,254,.1) !important;
  // border-right:4px solid #3C81FE;
  &:after{
    position: absolute;
    content: " ";
    right: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #3C81FE !important;
  }
}
/deep/.el-menu-item:hover{
  position: relative;
  background-color: rgba(60,129,254,.1) !important;
  // border-right:4px solid #3C81FE;
  &:after{
    position: absolute;
    content: " ";
    right: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #3C81FE !important;
  }
}
/deep/ .el-submenu .el-submenu__title{
  &:hover{
    background-color: rgba(60,129,254,.1) !important;
  }
}
/deep/ .el-submenu.is-opened .el-submenu__title{
  color: rgb(60, 129, 254) !important;
}
</style>

