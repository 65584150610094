import Vue from 'vue'
import App from './App.vue'
import '@/assets/styles/index.less'
import '@/assets/styles/common.less'
import '@/plugins/elementui.js'
import store from '@/store'
import router from './router'
import {getToken} from '@/utils/auth'
import {hasPermission} from '@/utils/hasPermission'
import 'default-passive-events'
import VueIntroStep from 'vue-intro-step'
Vue.component('vue-intro-step', VueIntroStep);

Vue.config.productionTip = false
Vue.prototype.hasPerm = hasPermission
Vue.prototype.isGMHerom2 = function() {
  const domain = window.location.hostname;
  return domain === 'gm.herom2.com'||domain === 'gm.zjkowg.com';
}
Vue.prototype.isSandBox = function() {
  const domain = window.location.hostname;
  return domain === '192.168.10.144';
}
const base = document.createElement('base');
base.href = '/'; // 设置绝对路径
document.head.appendChild(base);

const favicon = document.getElementById('dynamic-favicon');
if (Vue.prototype.isGMHerom2()) {
  // favicon.href = 'favicon-hero.ico'
  favicon.href = `${window.location.origin}/favicon-hero.ico`; // 使用绝对路径
} else {
  // favicon.href = `favicon.ico`;
  favicon.href = `${window.location.origin}/favicon.ico`; // 使用绝对路径
  // window.document.title = '沙盒推广平台'
}
if (Vue.prototype.isSandBox()) {
  window.document.title = '沙盒推广平台'
} else {
  window.document.title = 'GM服务平台'
}

router.beforeEach((to, from, next) => {
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === '/login') {
      // 如果已经登录，重定向到主页面
      next({ path: '/' });
    } else {
      if (store.getters.routes.length === 0) {
        store.dispatch('GenerateRoutes').then(accessRoutes => {
          // 根据roles权限生成可访问的路由表
          // 动态添加可访问路由表
          accessRoutes.map(item => {
            router.addRoute(item)
          })
          next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
       })
      } else {
        next();
      }
    }
  } else {
    if(to.path == '/login' || to.path == '/'){
      next();
    } else if (to.path == '/relogin' || to.path == '/') {
      next();
     }
    else{
      next({ path: '/login' });
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
  
}).$mount('#app')
