import { getToken ,setToken} from "@/utils/auth";
import { login, initIndex, btninfo, getStatus } from "@/api/user";
import Layout from '@/layout/index'
import { constantRoutes } from '@/router'

const state = {
  token: getToken(),
  userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
  routes: [],//全部路由
  addRoutes: [],//动态路由
  defaultRoutes: [],//默认动态路由
  topbarRouters: [],//加了个控制台路由对象的路由
  sidebarRouters: [],//动态和静态的全部路由
  btnlist: [],
  roleId: '',
  channelsId: '',//确定推广员一级二级身份
};

const mutations = {
  //更改用户审核状态
  CHANGE_STATUS:(state, status) => {
    state.userInfo.status = status
  },
  //设置按钮权限表
  SET_BTN_LIST: (state, routes) => {
    state.btnlist = routes
  },
  //设置登录者角色身份
  SET_ROLE_ID: (state, routes) => {
    state.roleId = routes
  },
  //确定推广员一级二级身份
  SET_CHANNELS_ID: (state, routes) => {
    state.channelsId = routes
  },
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_DEFAULT_ROUTES: (state, routes) => {
    state.defaultRoutes = constantRoutes.concat(routes)
  },
  SET_TOPBAR_ROUTES: (state, routes) => {
    // 顶部导航菜单默认添加控制台栏指向首页
    const index = [{
        path: 'index',
        meta: { title: '控制台', icon: 'dashboard' }
    }]
    state.topbarRouters = routes.concat(index);
  },
  SET_SIDEBAR_ROUTERS: (state, routes) => {
    state.sidebarRouters = routes
  },

  SET_TOKEN: (state, token) => {
    state.token = token;
    if(token==null||token==""){
      setToken("")
    }else{
      setToken(token)
    }
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
    // Vue.set(state, "userInfo", userInfo);
  },
  SET_USERINFOPHONE: (state, phone) => {
    state.userInfo.phone = phone;
  },
  SET_AVATAR: (state, avatar) => {
    state.userInfo.avatar = avatar;
  },
  SET_USERINBANKNUM: (state, banknum) => {
    state.userInfo.bank_info = banknum;
  }
};

const actions = {
  // 生成路由
  GenerateRoutes ({ commit }) {
    return new Promise(resolve => {
        // 向后端请求路由数据
      initIndex().then((response) => {
            btninfo().then((list) => {
              const datalist = list.data.permissions
              let role_id = list.data.admin.groups_name
              let channels_id = list.data.admin.channels_parent_id
              commit("SET_BTN_LIST", datalist);
              commit("SET_ROLE_ID", role_id);
              commit("SET_CHANNELS_ID", channels_id);
              console.log(state.btnlist,datalist,'按钮权限表');
              console.log(state.roleId, role_id, '登陆者身份');
              getStatus().then(res => {
                const tranStatus = res.data.status
                console.log(tranStatus, 'tranStatus');
                commit("CHANGE_STATUS", tranStatus);
              })
            })
            const rdata = JSON.parse(JSON.stringify(response.data.menuInfo))
            const rewriteRoutes = filterAsyncRouter(rdata)
            commit('SET_ROUTES', rewriteRoutes)
            resolve(rewriteRoutes)
        })
    })
  },
  // 用户登录
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, resject) => {
      login({ username: username.trim(), password: password })
        .then((res) => {
          const { data } = res;
          commit("SET_TOKEN",data.token);
          setToken(data.token)
          commit("SET_USERINFO", data.admin);
          localStorage.setItem("userInfo", JSON.stringify(data.admin))
          resolve();
        })
        .catch((err) => {
            resject(err);
        });
    });
  },
  logout({ commit }){
    commit("SET_TOKEN","");
    setToken("")
    commit("SET_USERINFO",{});
    // commit("SET_ROUTES",[]);
    localStorage.clear();
    location.reload();
  }
};
// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter (asyncRouterMap) {
  return asyncRouterMap.filter(route => {
      if (route.component) { 
          if (route.component === 'Layout') {
            route.component = Layout
          }
          else {
              route.name = route.meta.title
              route.component = loadView(route.component)
          }
      }
      if (route.children != null && route.children && route.children.length > 0) {
          route.children = filterAsyncRouter(route.children)
      } else {
          delete route['children']
          delete route['redirect']
      }
      return true
  })
}



export const loadView = (view) => { // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve)
}


export default {
  state,
  mutations,
  actions,
};
