import Vue from 'vue'
import {
    Alert,
    Aside,
    Button,
    Card,
    Cascader,
    Checkbox,
    Col,
    Container,
    DatePicker,
    Descriptions,
    DescriptionsItem,
    Dialog,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Empty,
    Form,
    FormItem,
    Header,
    Input,
    InputNumber,
    Loading,
    Main,
    Menu,
    MenuItem,
    MenuItemGroup,
    Message,
    MessageBox,
    Option,
    Pagination,
    Radio,
    RadioGroup,
    Row,
    Scrollbar,
    Select,
    Slider,
    Step,
    Steps,
    Submenu,
    Switch,
    Table,
    TableColumn,
    TabPane,
    Tabs,
    Tag,
    Timeline,
    TimelineItem,
    Tooltip,
    Tree,
    Upload,
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Dialog.props.closeOnClickModal.default = false;
Dialog.props.closeOnPressEscape.default = false;

Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message;
Vue
    .use(Slider)
    .use(Form)
    .use(FormItem)
    .use(Input)
    .use(Button)
    .use(Container)
    .use(Header)
    .use(Main)
    .use(Dropdown)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Card)
    .use(Select)
    .use(Option)
    .use(Table)
    .use(TableColumn)
    .use(Pagination)
    .use(Menu)
    .use(Aside)
    .use(MenuItemGroup)
    .use(MenuItem)
    .use(Submenu)
    .use(RadioGroup)
    .use(Radio)
    .use(Dialog)
    .use(Row)
    .use(Col)
    .use(Switch)
    .use(DatePicker)
    .use(Upload)
    .use(Tooltip)
    .use(Tag)
    .use(Checkbox)
    .use(Steps)
    .use(Step)
    .use(Loading)
    .use(Cascader)
    .use(InputNumber)
    .use(Alert)
    .use(Timeline)
    .use(TimelineItem)
    .use(Scrollbar)
    .use(Empty)
    .use(Tree)
    .use(Tabs)
    .use(TabPane)
    .use(Descriptions)
    .use(DescriptionsItem)