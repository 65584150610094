import VueRouter from "vue-router";
import Vue from "vue";
import Layout from "@/layout";

// 修改首页路由重复点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: "/",
    redirect: to => {
      return '/master'
    }
  },
  {
    path: "/login",
    component: (resolve) => require(["@/views/login"], resolve),
  },
  {
    path: "/relogin",
    component: (resolve) => require(["@/views/relogin"], resolve),
  },
  {
    path: "/404",
    component: (resolve) => require(["@/views/404"], resolve),
  },
  {
    path: "/master",
    component: Layout,
    redirect: "/master/page",
    name: '首页',
    children: [
      {
        path: "page",
        name: "page",
        component: (resolve) => require(["@/views/masterpage"], resolve),
      },
    ],
  },
];

// 动态路由
export const asyncRoutes = [
]

//hack 动态清除注册的路由
const createRouter = () =>
  new VueRouter({
    mode: "history", // 去掉url中的#
    routes:[...constantRoutes, ...asyncRoutes],
    scrollBehavior: () => ({ y: 0 }),
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
