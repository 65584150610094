import axios from 'axios'
import store from '../store'
import router from '../router'
import {getToken} from '@/utils/auth'
import {Message} from "element-ui";

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    timeout: 15000 // request timeout

})
service.defaults.timeout = 15000

// request interceptor
service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            config.headers['token'] = getToken()
        }

        //  config.headers['Content-Type'] =  'multipart/form-data'
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code === 1) { // 成功
            return Promise.resolve(res)
        } else if (res.code === 4001) { // token失效
            Message({message: res.msg, type: 'error'})
            store.dispatch('logout')
            router.replace('/login');
            return Promise.reject(res.msg);
        } else if (res.code === 0){ // 登录失败
            Message({message: res.msg, type: 'error'})
            // store.dispatch('logout')
            return Promise.reject(res.msg);
        }else if(res.code === 500){ //  请求接口失败
            Message({message: res.msg, type: 'error'})
            return Promise.reject(res.msg);
        }else if(res.code === 4002){ // 没权限
            Message({message: res.msg, type: 'error'})
            return Promise.reject(res.msg);
        }else{
            Message({message: res.msg, type: 'error'})
            return Promise.reject(res.msg);
        }

    },
    error => {
        Message({message: '网络繁忙', type: 'error'})
        return Promise.reject(error)
    }
)

export default service
  